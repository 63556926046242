import { useState } from "react";
import axios from "axios";

const TokenComponent = (item) => {

    let [price, setPrice] = useState(item.item.price);

    let handleChangePrice = (event) => {
        setPrice(event.target.value);
    }

    let changePrice = async () => {
        const token = localStorage.getItem('jwtToken');

        const change = await axios.post('https://api.ydchange.com/token/set', {
            code: item.item.code,
            price,
            token
        });
    }

    return (
        <div className="token-wrapper">
            <div className="token">
                <span className="token-name">
                    {item.item.code}
                </span>
                <input onChange={handleChangePrice} type="number" value={price} className="token-price" />
                <span onClick={changePrice} className="token-change">Change Price</span>
            </div>
        </div>
    )
}

export default TokenComponent;