import { useEffect, useState } from "react";
import axios from "axios";
import TokenComponent from "./TokenComponent";

const TokensComponent = () => {

    let [tokens, setTokens] = useState();

    useEffect(() => {
        const getTokens = async () => {
            const tokens = await axios.get('https://api.ydchange.com/token');

            if (tokens) {
                setTokens(tokens.data);
            }
        }

        getTokens();
    }, []);

    let approvesList = () => {
        return tokens.map(item => {
            return (
                <TokenComponent item={item} />
            )
        })
    }

    return (
        <div className="main">
            { tokens ? <div className="wrapper">{approvesList()}</div> : ''} 
        </div>
    );
}

export default TokensComponent;