import { NavLink } from "react-router-dom";

const MenuComponent = () => {


    return (
        <div className="menu-wrapper">
            <div className="menu">
                <NavLink to='/' className="menu-item">
                    List
                </NavLink>
                <NavLink to='/tokens' className="menu-item">
                    Tokens
                </NavLink>
            </div>
        </div>
    )
}

export default MenuComponent;