import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthGuard from './middleware/AuthGuard';
import ApproveListComponent from './components/ApproveListComponent';
import LoginComponent from './components/LoginComponent';
import MenuComponent from './components/MenuComponent';
import TokensComponent from './components/TokensComponent';

function App() {
  return (
    <BrowserRouter>
      <AuthGuard><MenuComponent /></AuthGuard>
      <Routes>
        <Route path='/tokens' element={<AuthGuard><TokensComponent /></AuthGuard>} />
        <Route path='/' element={<AuthGuard><ApproveListComponent /></AuthGuard>} />
        <Route path="/login" element={ <LoginComponent /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
